import React, { useState, useEffect } from "react"
import ImageMeta from "../components/ImageMeta"
import NuvoImage from "../components/NuvoImage"
import Layout from "../components/layout"
import Seamless from "../components/Seamless"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import LocationBlocks from "./contact/LocationBlocks"
import ButtonGroupMap from "../components/Button/ButtonGroupMap"

import GeneralHours from "@bit/azheng.joshua-tree.general-hours"
import { Ratings } from "../templates/index/RatingsAndReviews"
import { graphql } from "gatsby"
import AllSocialReviews from "../components/SocialReviews/AllSocialReviews"
import { SocialIconLink } from "../components/SocialReviews"

var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

import {
  getPostObj,
  splitCamelCaseToString,
  parseSearchQuery,
  telephoneStrip
} from "../utils/utils"
import LocationForms from "./contact/LocationForms"

const form =
  '<iframe class=\'seamlessdocsEmbededIframe\' src="https://secureform.seamlessdocs.com/f/68a6a02333da902f9aed94e366fb587f?embedded=true" width="100%" height="700px" frameborder="0" allowtransparency="true" sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"></iframe>'

const ContactUsPage = ({ pageContext, location, data }) => {
  var post
  var language
  let postObj = getPostObj(
    pageContext,
    data.allUniquePagesJson.nodes[0],
    data.allSpanishUniquePagesJson.nodes[0]
  )
  post = postObj.post
  language = postObj.language

  const locationHours =
    data.allLocationsJson.nodes[0].locationData.locationHours

  const locations = data.allLocationsJson.nodes

  let socials = locations[0].locationData.socialAccounts

  const allowedSocials = ["Facebook", "Instagram"]
  socials = socials.filter((s) => allowedSocials.includes(s.platform))

  const [activeForm, setActiveForm] = useState({ id: "", name: "" })
  const [pageState, setPageState] = useState("")

  useEffect(() => {
    if (typeof window === "undefined") return

    if (location.state?.formId) {
      setActiveForm({
        ...activeForm,
        id: location.state.formId,
        name: location.state.locationName
      })
    } else {
      setActiveForm({ id: "", name: "" })
    }
  }, [location.state?.formId])

  useEffect(() => {
    if (typeof window === "undefined") return

    if (pageState !== location.state?.key) {
      setPageState(location.state?.key)
    }
  }, [location.state?.key])

  const handleFormChange = (formId, locationName) => {
    if (formId === activeForm.id) {
      const form = document.getElementById("contact-page-form")
      setTimeout(() => {
        window.scroll({
          top: form.offsetTop - 100,
          left: 0,
          behavior: "smooth"
        })
      }, 600)

      return
    } else {
      setActiveForm((prevState) => ({
        id: formId,
        name: locationName
      }))
    }
  }

  return (
    <SharedStateProvider>
      <Layout
        className={`contact-us ${language === "es" ? "es" : null}`}
        layoutClass={"contact-fab"}
        language={language}
      >
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
        />
        <span className="menu-spacer"></span>

        <div className="contact-top-wrapper">
          <div className="is-hidden-desktop is-hidden-tablet">
            <NuvoImage
              className="splash"
              cloudName="nuvolum"
              publicId="KTOMS/DEV/mobile-contact-page-splash-marker"
              useAR
              width="auto"
            ></NuvoImage>
          </div>
          <div className="is-hidden-mobile">
            <NuvoImage
              className="splash"
              cloudName="nuvolum"
              publicId={post.googleMapsBackground.splashPublicId}
              useAR
              width="auto"
            ></NuvoImage>
          </div>

          <div className="contact-top-headings">
            <h1>{post.heading}</h1>
          </div>
        </div>

        <div className="body-section contact-us-body">
          <div className="columns">
            <div className="column is-4"></div>
            <div
              className="column is-10"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
              }}
            >
              <div className="contact-info is-hidden-tablet">
                {locations.length > 1 && (
                  <>
                    {/* <LocationBlocks language={language} locations={[locationTwoAll, locationOneAll]} /> */}
                  </>
                )}
                {locations.length === 1 && (
                  <>
                    <div className="contact-sidebar-block">
                      <h7>{language === "es" ? "Dirección" : "Address"}</h7>
                      <a
                        href={`${locations[0].locationData.googlePlaceLink}`}
                        target="_blank"
                      >
                        {locations[0].locationData.streetNumber +
                          " " +
                          locations[0].locationData.streetName}
                        <br />
                        {locations[0].locationData.cityName +
                          ", " +
                          locations[0].locationData.stateAbbr +
                          " " +
                          locations[0].locationData.cityZip}
                      </a>
                    </div>
                    <div className="contact-sidebar-block">
                      <h7>{language === "es" ? "Teléfono" : "Phone"}</h7>
                      <a
                        href={`tel:+1${telephoneStrip(
                          locations[0].locationData.phone
                        )}`}
                      >
                        {locations[0].locationData.phone}
                      </a>
                    </div>

                    <div className="contact-sidebar-block">
                      <GeneralHours
                        language={language}
                        contact={true}
                        locationHours={locationHours}
                      />
                    </div>

                    <div className="contact-sidebar-block mt-3">
                      {post.sidebar.hasThisSection && (
                        <div className="sidebar-buttons">
                          <h7>{post.sidebar.heading}</h7>
                          <ButtonGroupMap buttons={post.sidebar.buttons} />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div
                dangerouslySetInnerHTML={createHtml(
                  converter.makeHtml(
                    post.contactBody.heading + post.contactBody.blurb
                  )
                )}
              ></div>

              <div className="contact-form-section">
                {locations.length === 1 ? (
                  <>
                    <div className="inline-block">
                      <h5>Send Us a Message</h5>
                      <span className="required-fields">
                        <span className="required-asterisk">*</span> Required
                        Fields
                      </span>
                    </div>
                    <Seamless src="/contactForm.js" />
                  </>
                ) : (
                  <div>
                    <div className="locations-grid">
                      <LocationBlocks
                        language={language}
                        locations={locations}
                        setActiveForm={setActiveForm}
                        handleFormChange={handleFormChange}
                      />
                    </div>
                  </div>
                )}

                {/* USA NO FORM */}
                {/* {language === "es" &&
                    <>
                      <div className="inline-block">
                        <h5>Mandanos Un Mensaje</h5> 
                        <br/>
                        <span className="required-fields">
                          <span className="required-asterisk">*</span> Campos Requeridos
                        </span>
                      </div>
                      <Seamless src="/spanishContactForm.js" />
                    </>
                  } */}

                {/* <div className="seamless-form-wrapper-2" dangerouslySetInnerHTML={{ __html: form}}>
  </div> */}
              </div>
            </div>
            <div className="column is-2"></div>
            <div className="column is-narrow contact-info is-hidden-mobile">
              {locations.length > 1 && (
                <>
                  {/* <LocationBlocks language={language} locations={locations} /> */}

                  <div className="contact-sidebar-block">
                    {/* <Ratings language={language} google={google} facebook={facebook} eastGoogle={east} coastalGoogle={coastal} /> */}
                    <AllSocialReviews language={language} isColumn />

                    <div
                      className="contact-sidebar-block socials"
                      style={{ margin: "0 20px 40px 20px" }}
                    >
                      <h6 className="mt-0">Find us on social</h6>
                      <div style={{ display: "flex", gap: 20 }}>
                        {socials.map((social) => (
                          <React.Fragment key={social.platform}>
                            <SocialIconLink
                              platform={social.platform}
                              link={social.link}
                            />
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {locations.length === 1 && (
                <>
                  <div className="contact-sidebar-block">
                    <h7>{language === "es" ? "Dirección" : "Address"}</h7>
                    <a
                      href={`${locations[0].locationData.googlePlaceLink}`}
                      target="_blank"
                    >
                      {locations[0].locationData.streetNumber +
                        " " +
                        locations[0].locationData.streetName}
                      <br />
                      {locations[0].locationData.cityName +
                        ", " +
                        locations[0].locationData.stateAbbr +
                        " " +
                        locations[0].locationData.cityZip}
                    </a>
                  </div>
                  <div className="contact-sidebar-block">
                    <h7>{language === "es" ? "Teléfono" : "Phone"}</h7>
                    <a
                      href={`tel:+1${telephoneStrip(
                        locations[0].locationData.phone
                      )}`}
                    >
                      {locations[0].locationData.phone}
                    </a>
                  </div>

                  <div className="contact-sidebar-block">
                    <GeneralHours
                      language={language}
                      contact={true}
                      locationHours={locationHours}
                    />
                  </div>
                  <div className="contact-reviews">
                    {/* <AllSocialReviews isColumn /> */}

                    {post.sidebar.hasThisSection && (
                      <div className="sidebar-buttons">
                        <h7>{post.sidebar.heading}</h7>
                        <ButtonGroupMap buttons={post.sidebar.buttons} />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="column is-5 is-hidden-tablet"></div>
          </div>

          {language === "es" && (
            <div className="columns">
              <div className="column is-4"></div>
              <div className="column">
                <div className="contact-reviews contact-reviews-es big">
                  {/* <SocialReviews inlineStars iconless google facebook googleHeading language={language} /> */}
                  {/* <Ratings language={language} google={google} facebook={facebook} eastGoogle={east} coastalGoogle={coastal} /> */}
                </div>
              </div>
              <div className="column is-4"></div>
            </div>
          )}
        </div>

        {/* {language !== "es" && (
          <LocationForms
            activeForm={activeForm}
            setActiveForm={setActiveForm}
            pageUpdated={pageState}
          />
        )} */}
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query contactUsPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        schemaPage
        contactBody {
          heading
          blurb
        }
        sidebar {
          hasThisSection
          heading
          buttons {
            button {
              href
              buttonText
              appearance
              destination
            }
          }
        }
        googleMapsBackground {
          pinLink
          pinTextLineOne
          pinTextLineTwo
          splashPublicId
        }
      }
    }

    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        schemaPage
        contactBody {
          heading
          blurb
        }
        sidebar {
          address {
            cityStateZip
            street
          }
          hours
          phone {
            phonePlusFormat
            phoneText
          }
        }
        googleMapsBackground {
          pinLink
          pinTextLineOne
          pinTextLineTwo
          splashPublicId
        }
      }
    }
    allLocationsJson(sort: { fields: locationData___order }) {
      nodes {
        locationData {
          ...locationDataFields
        }
        pageData {
          ...locationPageURL
          layout_3 {
            formSection {
              hasForm
              seamlessShortcode
            }
          }
        }
      }
    }
  }
`

export default ContactUsPage
