import React, { useState, useEffect } from 'react'

import "./Seamless.css";

const Seamless = ({ src }) => {

  const [formId, setFormId] = useState('')
  
  useEffect(() => {
    const id = src.replace('/', '').replace('.js', '')
    const script = document.getElementById(id)

    if (!script && src) {
      setFormId(id)
      createScriptTag(src, id)
    }

    return (() => {
      if (script) {
        script.remove()
      }
    })

  }, [src, formId])

  const createScriptTag = (src, id) => {
    const script = document.createElement("script");

    script.src = src;
    script.async = true;
    script.id = id

    document.body.appendChild(script);
  };

  return (
    <div className="seamless-form-wrapper" id="seamless-form-wrapper">
              
    </div>
  )
}

export default Seamless